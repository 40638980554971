<template>
  <b-card-code title="Vulnerability Database">
    <button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      class="btn ml-auto btn-primary m-1"
    >
       <router-link class="text-white" :to="'/cvd/add'">Add New</router-link>
    </button>
  <!-- Search Function -->
      <b-card-code class="bg-light-secondary mb-1" title="Search">
        <b-form inline style="display: inline-block; width: 100%;">
            <div class="row" style="width: 100%;padding:5px">
            <b-form-input
              class="ml-1"
              id="name_filter"
              v-model="name_filter"
              style="width: 60%"
              name="name_filter"
              placeholder="Search with Name"
            />
            <b-button
              style="width: 20%"
              class="ml-1"
              variant="primary"
              @click="load"
            >
              Search
            </b-button>
          </div>
        </b-form>
      </b-card-code>
    <!-- End of search form -->
    <b-table
      responsive
      id="cvdTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      hover
    >
      <template #cell(name)="data">
            <router-link :to="'/cvd/vulnerability/detail/' + data.item.id">{{
              data.item.name
            }}</router-link>
      </template>
      <template #cell(actions)="data">
        <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editCVD(data.item.id)"
            >Edit</b-dropdown-item
          >

          <!-- <b-dropdown-item @click="allocateScanners(data.item.org_id)">Default Scanners</b-dropdown-item> -->
          <b-dropdown-divider />
          <b-dropdown-item @click="deleteOrg(data.item.id)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="cvdTable"
      use-router
    />
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "id", label: "#" },
        { key: "name", label: "Origin Name" },
        { key: "vm_name", label: "Name" },
        { key: "severity", label: "Severity" },
        { key: "owasp_category", label: "OWASP Cat" },
        { key: "cvss_score", label: "CVSS Score" },
        //   { key: "actions", label: "Actions" },
      ],
      name_filter: ''
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load();
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function () {
      let url = process.env.VUE_APP_BASEURL + "asset/master_vulns?query=true";
      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page;
        url = url+"&page="+this.currentPage
      }
      if (this.name_filter)
        url = url+"&name="+this.name_filter
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },
    addOrg() {
      this.$router.push({ name: "Add Organization" });
    },
    editCVD(id) {
      this.$router.push({ name: "cvd_edit", params: { id: id } });
    },
    allocateScanners(id) {
      this.$router.push({ name: "Allocated Scanners", params: { id: id } });
    },
    deleteOrg(id) {
      if (confirm("Do you really want to delete?")) {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org" +
            id +
            "/",
        };
        this.$http(options).then((res) => {
          console.log(res);
          this.load();
          //if(res.data.org_id){
          // this.$router.push({name: 'Organizations'});
          // }
        });
      }
    },
  },
};
</script>
